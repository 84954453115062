const utils = {
  buildTimeString(displayTime, showHour, showSecond = true) {
    const h = Math.floor(displayTime / 3600);
    const m = Math.floor((displayTime / 60) % 60);
    let s = Math.floor(displayTime % 60);

    if (s < 10) s = `0${s}`;
    let text = "";
    if (showSecond) {
      text = `${m}:${s}`;
    } else {
      text = `${m}`;
    }

    if (showHour) {
      if (m < 10) text = `0${text}`;
      text = `${h}:${text}`;
    }
    return text;
  },
  diffUTCDateTime(startDate, endDate) {
    let utcStart = new Date(startDate);
    startDate = Date.UTC(
      utcStart.getFullYear(),
      utcStart.getMonth(),
      utcStart.getDate(),
      utcStart.getHours(),
      utcStart.getMinutes(),
      utcStart.getSeconds()
    );
    let utcEnd = new Date(endDate);
    endDate = Date.UTC(
      utcEnd.getFullYear(),
      utcEnd.getMonth(),
      utcEnd.getDate(),
      utcEnd.getHours(),
      utcEnd.getMinutes(),
      utcEnd.getSeconds()
    );
    return (endDate - startDate) / 1000;
  },
  convertToUTC(currentTime) {
    return `${currentTime.getUTCFullYear()}-${
      currentTime.getUTCMonth() + 1 <= 9
        ? "0" + (currentTime.getUTCMonth() + 1)
        : currentTime.getUTCMonth() + 1
    }-${
      currentTime.getUTCDate() <= 9
        ? "0" + currentTime.getUTCDate()
        : currentTime.getUTCDate()
    }T${
      currentTime.getUTCHours() <= 9
        ? "0" + currentTime.getUTCHours()
        : currentTime.getUTCHours()
    }:${
      currentTime.getUTCMinutes() <= 9
        ? "0" + currentTime.getUTCMinutes()
        : currentTime.getUTCMinutes()
    }:${
      currentTime.getUTCSeconds() <= 9
        ? "0" + currentTime.getUTCSeconds()
        : currentTime.getUTCSeconds()
    }`;
  },
  probeSupport() {
    return utils.probeDrm().then(drm => {
      const media = utils.probeMedia();
      const obj = {
        media,
        drm
      };

      return obj;
    });
  },

  probeMedia() {
    const support = {};
    const testMimeTypes = [
      'video/mp4; codecs="avc1.42E01E"',
      'video/mp4; codecs="avc3.42E01E"',
      'video/mp4; codecs="hev1.1.6.L93.90"',
      'video/mp4; codecs="hvc1.1.6.L93.90"',
      'video/mp4; codecs="hev1.2.4.L153.B0"; eotf="smpte2084"', // HDR HEVC
      'video/mp4; codecs="hvc1.2.4.L153.B0"; eotf="smpte2084"', // HDR HEVC
      'video/mp4; codecs="vp9"',
      'video/mp4; codecs="vp09.00.10.08"',
      'audio/mp4; codecs="mp4a.40.2"',
      'audio/mp4; codecs="ac-3"',
      'audio/mp4; codecs="ec-3"',
      'audio/mp4; codecs="opus"',
      'audio/mp4; codecs="flac"',
      'video/webm; codecs="vp8"',
      'video/webm; codecs="vp9"',
      'video/webm; codecs="vp09.00.10.08"',
      'audio/webm; codecs="vorbis"',
      'audio/webm; codecs="opus"',
      'video/mp2t; codecs="avc1.42E01E"',
      'video/mp2t; codecs="avc3.42E01E"',
      'video/mp2t; codecs="hvc1.1.6.L93.90"',
      'video/mp2t; codecs="mp4a.40.2"',
      'video/mp2t; codecs="ac-3"',
      'video/mp2t; codecs="ec-3"',
      "text/vtt",
      'application/mp4; codecs="wvtt"',
      "application/ttml+xml",
      'application/mp4; codecs="stpp"'
    ];

    testMimeTypes.forEach(type => {
      support[type] = MediaSource.isTypeSupported(type);
      const basicType = type.split(";")[0];
      support[basicType] = support[basicType] || support[type];
    });
    return support;
  },

  probeDrm() {
    const testKeySystems = [
      "org.w3.clearkey",
      "com.widevine.alpha",
      "com.microsoft.playready",
      "com.apple.fps.2_0",
      "com.apple.fps.1_0",
      "com.apple.fps",
      "com.adobe.primetime"
    ];

    const basicVideoCapabilities = [
      { contentType: 'video/mp4; codecs="avc1.42E01E"' },
      { contentType: 'video/webm; codecs="vp8"' }
    ];
    const basicConfig = {
      videoCapabilities: basicVideoCapabilities
    };
    const offlineConfig = {
      videoCapabilities: basicVideoCapabilities,
      persistentState: "required",
      sessionTypes: ["persistent-license"]
    };

    // Try the offline config first, then fall back to the basic config.
    const configs = [offlineConfig, basicConfig];

    const support = new Map();
    const testSystem = async keySystem => {
      try {
        const access = await navigator.requestMediaKeySystemAccess(
          keySystem,
          configs
        );
        support.set(keySystem, true);
        await access.createMediaKeys();
      } catch (e) {
        support.set(keySystem, false);
      }
    };

    const tests = testKeySystems.map(keySystem => testSystem(keySystem));
    return Promise.all(tests).then(() => utils.asObject(support));
  },

  asObject(map) {
    const obj = {};
    map.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  },
  shuffle(oldarray) {
    let array = [...oldarray];
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  },
  guid() {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
  }
};

export default utils;
